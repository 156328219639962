import { graphql, useStaticQuery } from 'gatsby';
/** @jsx jsx */
import { Box, Text, jsx } from 'theme-ui';
import { Room, RoomGroup } from './rooms';

export const CONTENTFUL_BUNKHOSUES = graphql`
  query BunkHousesQuery {
    bunkhouses: contentfulRoomType(slug: { eq: "bunkhouses" }) {
      ...RoomTypeFragment
      rooms {
        ...RoomFragment
      }
    }
  }
`;

export default function BunkHouses() {
  const { bunkhouses } = useStaticQuery(CONTENTFUL_BUNKHOSUES);

  return <RoomGroup roomType={bunkhouses} hideTopSection />;
}
